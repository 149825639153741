<template>
  <div class="h-screen flex w-full bg-full-grey">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block lg:w-1/2
                mx-auto
                self-center
              "
            >
              <img
                src="@/assets/images/pages/verify-resend.png"
                alt="login"
                class="mx-auto main-img"
              />
            </div>
            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                self-center
                d-theme-dark-bg
              "
            >
              <div class="px-8 pt-12 pb-12">
                <div class="vx-card__title">
                  <h4 class="mb-4">Resend verification code</h4>
                  <p>Please enter your email address.</p>
                </div>

                <form @submit.prevent="resend">
                  <vs-input
                    v-model="email"
                    v-validate="'required|email'"
                    type="email"
                    name="email"
                    label-placeholder="Email"
                    class="w-full"
                  />
                  <v-error :err="errors" field="email" />

                  <div
                    class="
                      flex flex-wrap
                      justify-between
                      flex-col-reverse
                      sm:flex-row
                      mt-6
                    "
                  >
                    <vs-button
                      type="border"
                      to="/login"
                      class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >
                      Go to login
                    </vs-button>
                    <vs-button
                      :disabled="!validateForm"
                      class="w-full sm:w-auto"
                      @click="resend"
                    >
                      Send code
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { AuthAction } from '@/store/actionTypes';
import { getMessageFromError } from '@/helpers';

import vError from '@/components/VError';

export default {
  components: {
    vError,
  },
  data: () => ({
    email: '',
  }),
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== '';
    },
  },
  methods: {
    ...mapActions('auth', [AuthAction.resendVerificationCode]),

    async resend() {
      this.$vs.loading();

      try {
        const { message } = await this.resendVerificationCode({
          email: this.email,
        });
        this.notifySuccess('Verification code sent', message);

        this.$router.push({ name: 'login' }).catch();
      } catch (error) {
        this.notifyError(error, getMessageFromError(error));
      }

      this.$vs.loading.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-img {
  width: 70%;
}
</style>
